<!-- 满意度调查 -->
<template>
  <div class="satisfactionBox">
    <div class="fell-box">
      <div class="st-head">
        <p>满意度调查</p>
      </div>
      <div v-if="feedback" class="fe-mid">
        <p>您的反馈和评价有助于我们为您提供更好的学习服务</p>
      </div>
      <textarea v-if="feedback" v-model="fellVal"></textarea>
      <div class="fe-info">
        <p class="tt">分项评分：<span>(匿名统计，所有人不可见)</span></p>
        <div class="f-inner">
          <ul class="list">
            <li v-for="(i,t) in fellList" :key="t">
              <p class="title">{{ i.type_cn }}:</p>
              <div class="item">
                <div class="dissatisfaction">不满意</div>
                <div class="progress">
                  <el-slider
                    v-model="i.user_score"
                    :max="i.score"
                    :style="{'--text':`'${i.user_score}分'`}"
                    :show-tooltip="false"
                  ></el-slider>
                </div>
                <div class="satisfaction">很满意</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <button stlye="cursor:pointer;" @click="submit()">提交</button>
    </div>
  </div>
</template>

<script>

import { getUserPostSatisfaction, saveUserPostSatisfaction } from '@/api/mine'
export default {
  data() {
    return {
      fellVal: '',
      // 满意度进度列表
      fellList: [],
      feedback: '', // 判断是否显示满意度意见
      satisfaction_id: ''
    }
  },
  beforeCreate() {
  },
  created() {

  },
  mounted() {
    this.getData()
  },
  beforeDestroy() {

  },
  methods: {
    getData() {
      getUserPostSatisfaction().then((res) => {
        if (res.errNo == 0) {
          console.log(res)
          this.fellList = res.result.define
          this.fellVal = res.result.info.user_post_comment
          this.feedback = res.result.info.feedback
          this.satisfaction_id = res.result.info.id
        }
      })
    },
    // 保存
    submit() {
      var status = this.fellList.every((item) => {
        return item.user_score == 0
      })
      if (status) {
        return this.$message.error('您还未打分')
      }
      var info = {
        user_post_comment: this.fellVal,
        satisfaction_id: this.satisfaction_id,
        define: JSON.stringify(this.fellList)
      }
      saveUserPostSatisfaction(info).then((res) => {
        if (res.errNo == 0) {
          this.$message.success(res.result)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.satisfactionBox{
  z-index: 10005;
  .fell-box{
    margin: 0 auto;
    // border: 7px solid #ddd;
    background: #fff;
    width: 781px;
    min-height: 609px;
    .st-head{
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-size: 30px;
    text-align: center;
    position: relative;
    color: #ee2e2e;
    margin-top: 24px;
  }
  .cha{
    position: absolute;
    right: 10px;
    top: -14px;
    cursor:pointer;
  }
  .fe-mid{
    width: 570px;
    height: 54px;
    margin: 0 auto;
    display: flex;
    p{
      font-size: 16px;
      color: #000;
      line-height: 54px;
      margin-right: 20px;
    }
    span{
      display: inline-block;
      line-height: 54px;
    }
   }
    textarea{
      width: 569px;
      height: 82px;
      border: 1px solid #ddd;
      outline: none;
      // margin-left: 100px;
      box-sizing: border-box;
      padding: 5px;
      resize:none
    }
    .fe-info{
      width: 585px;
      min-height: 316px;
      margin-left: 100px;
      margin-top: 10px;
      .tt{
        text-align: left;
        font-size: 16px;
        color: #000;
        margin-bottom: 20px;
        span{
          color: #999;
          font-size: 14px;
        }
      }
      .f-inner{
        width: 570px;
        .list{
          overflow:visible;
          .title{
            text-align: left;
            margin-bottom: 24px;
          }
        }
        .list .item{
          width: 450px;
          height: 45px;
          margin:0 auto;
          display: flex;
          align-items: center;
          p{
            width: 130px;
            color: #000;
          }
          .dissatisfaction,.satisfaction{
            width: 50px;
            color: #999;
            display: inline-block;
            margin: 0 12px;
          }
          .dissatisfaction{
            margin-right: 24px;
          }
          .progress{
              width: 300px;
              box-sizing: border-box;
              /deep/.el-slider__bar{
                background: #ee2e2e;
              }
              /deep/.el-slider__button{
                border:none;
                width: 30px;
                height: 30px;
                background: url('https://newoss.zhulong.com/edu/202007/09/31/095331y530t81smomzhyuy.png') no-repeat;
                background-size: 100% 100%;
                position: relative;
              }
              /deep/.el-slider__button::before{
                content: var(--text);
                position: absolute;
                font-size: 12px;
                width: 40px;
                height: 20px;
                line-height: 19px;
                top: -26px;
                left: 5px;
                border-radius: 3px;
                background: #ee2e2e;
                color: #fff;
              }
              /deep/.el-slider__button::after{
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                top: -7px;
                left: 10px;
                background: url('../../../assets/image/shoucang.png') no-repeat;
                background-position: -3px 3px;
                transform: rotate(180deg);
              }
          }
        }
      }
    }
    button{
      width: 163px;
      height: 36px;
      background: #ee2e2e;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 5px;
      margin-top: 30px;
      margin-bottom: 27px;
    }
  }
}
.jus-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jus-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.jus-spB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.red {
  color: #ee2e2e;
}
</style>
